<template>
  <div>
    <el-card class="card" :body-style="{ padding: '5px' }" v-loading="loading">
      <van-cell-group>
        <van-cell class="font" title="序列号">
          <template #right-icon>
            <div class="fontsize">{{ serialNumber }}</div>
          </template>
        </van-cell>
        <van-cell class="font" title="温度返回时间">
          <template #right-icon>
            <div class="fontsize">{{ wxcw03 ? wxcw03.dataTime : '-' }}</div>
          </template>
        </van-cell>
        <van-grid :gutter="0" :border="true" :column-num="2">
          <van-grid-item>
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t1 && wxcw03.t1.toFixed(1).length > 5) ? '0' : '16px' }" title="T1">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t1) ? wxcw03.t1.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item>
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t2 && wxcw03.t2.toFixed(1).length > 5) ? '0' : '16px' }" title="T2">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t2) ? wxcw03.t2.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item>
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t3 && wxcw03.t3.toFixed(1).length > 5) ? '0' : '16px' }" title="T3">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t3) ? wxcw03.t3.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item>
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t4 && wxcw03.t4.toFixed(1).length > 5) ? '0' : '16px' }" title="T4">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t4) ? wxcw03.t4.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item v-if="deviceType != 'WXCW80'">
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t5 && wxcw03.t5.toFixed(1).length > 5) ? '0' : '16px' }" title="T5">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t5) ? wxcw03.t5.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item v-if="deviceType != 'WXCW80'">
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t6 && wxcw03.t6.toFixed(1).length > 5) ? '0' : '16px' }" title="T6">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t6) ? wxcw03.t6.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item v-if="deviceType != 'WXCW80'">
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t7 && wxcw03.t7.toFixed(1).length > 5) ? '0' : '16px' }" title="T7">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t7) ? wxcw03.t7.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
          <van-grid-item v-if="deviceType != 'WXCW80'">
            <van-cell class="large-font" :style="{ 'padding-right': (wxcw03 && wxcw03.t8 && wxcw03.t8.toFixed(1).length > 5) ? '0' : '16px' }" title="T8">
              <template #right-icon>
                <div class="value-fontsize">{{ (wxcw03 && wxcw03.t8) ? wxcw03.t8.toFixed(1) + '℃' : '-&emsp;' }}</div>
              </template>
            </van-cell>
          </van-grid-item>
        </van-grid>
        <van-cell title="电量">
          <el-progress :text-inside="true" :stroke-width="26" :text-color="'blue'" :percentage="wxcw03 ? wxcw03.dianLiang : 0" :status="wxcw03 && wxcw03.dianLiang > 20 ? 'success' : 'exception'"></el-progress>
        </van-cell>
      </van-cell-group>
    </el-card>
    <van-button class="xuanfu" type="primary" round icon="replay" @click="getLastTemperature"></van-button>
    <van-button class="xuanfu2" type="warning" round icon="eye-o" :to="'/WapWXCW03View/' + serialNumber"></van-button>
    <van-button class="xuanfu1" type="info" round icon="setting-o" :to="'/WapWXCW03Setting/' + serialNumber"></van-button>
  </div>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/digital.css";
Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      wxcw03: null,
      loading: false,
      deviceType: "",
    };
  },
  methods: {
    setupdeviceType() {
      let that = this;
      that.axios
        .post("Base_Device/GetModelBySerialNumber", {
          serialNumber: that.serialNumber,
        })
        .then(function (response) {
          that.deviceType = response.data.data.deviceType;
        });
    },
    getLastTemperature() {
      let that = this;
      that.loading = true;
      that.axios
        .post("WXCW03_Result/GetLastTemperature", {
          serialNumber: that.serialNumber,
        })
        .then(function (response) {
          that.loading = false;
          that.wxcw03 = response.data.data;
        });
    },
  },
  mounted() {
    let that = this;
    that.setupdeviceType();
    document.title = "无线测温仪";
    that.getLastTemperature();
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
}
.xuanfu {
  position: absolute;
  bottom: 20px;
  left: 15px;
}

.xuanfu1 {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

.xuanfu2 {
  position: absolute;
  bottom: 20px;
  left: 45%;
}

.large-font {
  font-size: 1.2em;
}
.value-fontsize {
  font-family: "digital";
  font-size: 1.4em;
  color: rgb(41, 227, 41);
}
.font {
  font-size: 0.82em;
}
.fontsize {
  font-size: 1.2em;
  color: rgb(41, 227, 41);
}
</style>